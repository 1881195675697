<template>
    <div>
        <CreateSchedulesDetail
            :filterObject="filterObject"
            :scheduleHeader="scheduleHeader"
            :scheduleListDetail="scheduleListDetail"
            :scheduleSingleRow="scheduleSingleRow"
            :scheduleDetailHeader="scheduleDetailHeader"
            :scheduleDetailRow="scheduleDetailRow"
            :menuwidth="menuwidth"
            @openFilterPanel="openFilterPanel"
            @rowDownloadActionButton="rowDownloadActionButton"
            @multiSelectFilterChange="multiSelectFilterChange"
            @date="date"
            @clearAllFilters="clearAllFilters"
        ></CreateSchedulesDetail>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>
    </div>
</template>

<script>
import get from "lodash/get";
import CreateSchedulesDetail from "../components/CreateSchedulesDetail.vue";
import store from "@/store";
import { SCHEDULE_ACTIONS } from "@/store/modules/schedule/contants";
import { mapState } from "vuex";
import NotificationComponent from '../../../ui_component/notificationComponent.vue'

export default {
    beforeCreate() {
        this.$store = store;
    },
    name: "schedule-detail-container",
    components: {
        CreateSchedulesDetail,
        NotificationComponent,
    },
    data() {
        return {
            scheduleHeader: [
                {
                    fieldName: "feedname",
                    text: "Feed/Report Name",
                    width: "200px",
                },
                {
                    fieldName: "partner",
                    text: "Partner",
                    width: "130px",
                },
                {
                    fieldName: "schedule",
                    text: "Schedule",
                    width: "150px",
                },
                {
                    fieldName: "formattext",
                    text: "Format",
                    width: "110px",
                },
                {
                    fieldName: "timepercent",
                    text: "On Time",
                    width: "110px",
                },
                {
                    fieldName: "deliverypercent",
                    text: "Delivered",
                    width: "110px",
                },
                {
                    fieldName: "deliverymethod",
                    text: "Delivery Method",
                    width: "110px",
                },
            ],

            scheduleDetailHeader: [
                {
                    fieldName: "filename",
                    text: "Feed/Report Name",
                    width: "50%",
                },
                {
                    fieldName: "deliverytime",
                    text: "Delivered Time",
                    width: "20%",
                },
                {
                    fieldName: "ontime",
                    text: "On Time",
                    width: "12%",
                },
                {
                    fieldName: "isdelivered",
                    text: "Delivered",
                    width: "12%",
                },
                {
                    fieldName: "actions",
                    text: "Actions",
                    width: "6%",
                    align: "right",
                },
            ],

            scheduleDetailRow: [],
            scheduleSingleRow: [],
            todaysDate: "",
            minDate: "",
            maxDate: "",
            filterObject: [
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
                {
                    filterHeader: "On Time",
                    selectionType: "multiple",
                    filterList: [],
                },
                {
                    filterHeader: "Delivered",
                    selectionType: "multiple",
                    filterList: [],
                },
            ],
            ScheduledFeedId: 0,
            onTimeFilterValue:false,
            lateFilterValue:false,
            onDeliveredFilterValue:false,
            notDeliveredFilterValue:false,
            showNotification: false,
            notificationMessage: "",
            notificationType: "",
            selectedDate: "",
            initialSelectedDate: "",
        };
    },
    props: {
        menuwidth: {
            type: String,
        },
    },
    created() {
        // code for today's date
        let response = new Date().toLocaleDateString();
        let temp = response.split("/");
        if (temp[0].length == 1) {
            temp[0] = "0" + temp[0];
        }
        if (temp[1].length == 1) {
            temp[1] = "0" + temp[1];
        }
        this.todaysDate = temp[2] + "-" + temp[0] + "-" + temp[1];
    },
    async mounted() {
        const ScheduledFeedId = await this.$route.query.ScheduledFeedId;
        this.ScheduledFeedId = ScheduledFeedId;
        this.initialSelectedDate =  await this.$route.query.date;
        this.selectedDate = this.initialSelectedDate;
        var data = {
            ScheduledFeedId: this.ScheduledFeedId,
            onTime: null,
            onDelivered: null,
            date: this.selectedDate,
        };
        this.commonExpandFunction(data);
    },
    computed: {
        ...mapState({
            scheduleFileList(state) {
                return get(state, "schedule.scheduleFiles.scheduleFiles", []);
            },
            scheduleListDetail(state) {
                return get(state, "schedule.scheduleFiles.__loading__", false);
            },
            downloadScheduleResponse(state) {
                return get(
                    state,
                    "schedule.downloadSchedule.downloadSchedule",
                    []
                );
            },
        }),
    },
    methods: {

        // common File API call
        async commonExpandFunction(data) {
            await this.$store.dispatch(
                SCHEDULE_ACTIONS.FETCH_SCHEDULE_FILE_LIST,
                {
                    data,
                }
            );
            this.scheduleDetailRow = this.scheduleFileList.files;
            this.scheduleSingleRow = [this.scheduleFileList.scheduleinfo];
            let isvelotemplate = this.scheduleSingleRow[0].isvelotemplate;
            if (this.scheduleSingleRow != undefined) {
                this.scheduleSingleRow.forEach((item) => {
                    item.timepercent = item.timepercent === null 
                        ? '\u2014' 
                        : Math.ceil(item.timepercent) + "% On Time";
                    item.deliverypercent = item.deliverypercent === null
                        ? '\u2014' 
                        : Math.ceil(item.deliverypercent) + "% Delivered";
                });
            }
            if (this.scheduleDetailRow.length > 0) {
                this.scheduleDetailRow.forEach((item) => {
                    if(item.ontime == null) {
                        item.ontime = "\u2014";
                    } if (item.ontime == true) {
                        item.ontime = "On Time";
                    } if (item.ontime == false) {
                        item.ontime = "Late";
                    } if(item.isdelivered == null) {
                        item.isdelivered = "\u2014";
                    } if (item.isdelivered == true) {
                        item.isdelivered = "Delivered";
                    } if (item.isdelivered == false) {
                        item.isdelivered = "Not Delivered";
                    } if(item.deliverytime == null) {
                        item.deliverytime = "\u2014";
                    }
                    item.isvelotemplate = isvelotemplate;
                });
            } else{
                this.scheduleDetailRow = undefined;
            }
        },

        // row-download functionality
        async rowDownloadActionButton(value) {
            let data = value;
            await this.$store.dispatch(
                SCHEDULE_ACTIONS.DOWNLOAD_SCHEDULE_LIST,
                {
                    data,
                }
            );
            let response = this.downloadScheduleResponse;
            if (response.length !== 0) {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${value.filename}`);
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(link.href);
            }
            else{
                this.notificationResponse("No File Found");
            }
        },

        // common notification event
        notificationResponse(ntfnfailureMsg) {
                this.showLoader = false;
                this.notificationKey = "error-default";
                this.notificationMessage = ntfnfailureMsg;
                this.notificationType = "error";
                this.showNotification = true;
        },

        // open filter-panel
        openFilterPanel(selectedFilter) {
            this.getDeliveredList(selectedFilter);
            this.getOnTimeList(selectedFilter);
            this.getDateFilter();
        },

        // date-filter 
        getDateFilter() {
            this.filterObject[0].filterHeader = "Date";
            this.filterObject[0].selectionType = "date";
            var array = [
                {
                    id: 1,
                    isdisabled: false,
                    lable: "date",
                    value: new Date (this.selectedDate),
                    minMaxDates: {
                        min: new Date(2000, 0, 1),
                        max: new Date(this.todaysDate),
                    },
                },
            ];
            this.filterObject[0].filterList = array;
        },

        // multi-select filter
        async multiSelectFilterChange(event, value, headerTypeDetails) {
            this.getDateFilter();
            for (let i = 0; i < this.filterObject.length; i++) {
                if (this.filterObject[i].filterHeader == headerTypeDetails) {
                    for (
                        let j = 0;
                        j < this.filterObject[i].filterList.length;
                        j++
                    ) {
                        if (
                            this.filterObject[i].filterList[j].lable ==
                            value.lable
                        ) {
                            this.filterObject[i].filterList[j].isChecked =
                                event;
                        }
                    }
                }
            }
            if(event == true){
                if(value.lable == "On Time"){
                    this.onTimeFilterValue = true;
                }else if(value.lable == "Late"){
                    this.lateFilterValue = true;
                }else if(value.lable == "Delivered"){
                    this.onDeliveredFilterValue = true;
                }else if(value.lable == "Not Delivered"){
                    this.notDeliveredFilterValue = true;
                }
            }
            if(event == false){
                if(value.lable == "On Time"){
                    this.onTimeFilterValue = false;
                }else if(value.lable == "Late"){
                    this.lateFilterValue = false;
                }else if(value.lable == "Delivered"){
                    this.onDeliveredFilterValue = false;
                }else if(value.lable == "Not Delivered"){
                    this.notDeliveredFilterValue = false;
                }
            }
            this.commonMultiSechduleFilterChange();
        },

        // common multi-select filter event
        commonMultiSechduleFilterChange(){
            let selectedScheduleDetailFilter = {};
            // single selection of filter-panel
            if (this.onTimeFilterValue == true && this.lateFilterValue == false && this.onDeliveredFilterValue == false && this.notDeliveredFilterValue == false) {
                selectedScheduleDetailFilter.onTime = true;
            }
            else if (this.onTimeFilterValue == false && this.lateFilterValue == true && this.onDeliveredFilterValue == false && this.notDeliveredFilterValue == false) {
                selectedScheduleDetailFilter.onTime = false;
            } 
            else if (this.onTimeFilterValue == false && this.lateFilterValue == false && this.onDeliveredFilterValue == true && this.notDeliveredFilterValue == false) {
                selectedScheduleDetailFilter.onDelivered = true;
            }
            else if (this.onTimeFilterValue == false && this.lateFilterValue == false && this.onDeliveredFilterValue == false && this.notDeliveredFilterValue == true) {
                selectedScheduleDetailFilter.onDelivered = false;
            }
            // double-selection of filter-panel
            // 1.On Time true
            else if (this.onTimeFilterValue == true && this.lateFilterValue == true && this.onDeliveredFilterValue == false && this.notDeliveredFilterValue == false) {
                selectedScheduleDetailFilter.onDelivered = true;
            }
            else if (this.onTimeFilterValue == true && this.lateFilterValue == false && this.onDeliveredFilterValue == true && this.notDeliveredFilterValue == false) {
                selectedScheduleDetailFilter.onTime = true;
                selectedScheduleDetailFilter.onDelivered = true;
            }
            else if (this.onTimeFilterValue == true && this.lateFilterValue == false && this.onDeliveredFilterValue == false && this.notDeliveredFilterValue == true) {
                selectedScheduleDetailFilter.onTime = true;
                selectedScheduleDetailFilter.onDelivered = false;
            }
            // 2.Late true
            else if (this.onTimeFilterValue == false && this.lateFilterValue == true && this.onDeliveredFilterValue == true && this.notDeliveredFilterValue == false) {
                selectedScheduleDetailFilter.onTime = false;
                selectedScheduleDetailFilter.onDelivered = true;
            }
            else if (this.onTimeFilterValue == false && this.lateFilterValue == true && this.onDeliveredFilterValue == false && this.notDeliveredFilterValue == true) {
                selectedScheduleDetailFilter.onTime = false;
                selectedScheduleDetailFilter.onDelivered = false;
            }
            // 3.On delivered true
            else if (this.onTimeFilterValue == false && this.lateFilterValue == false && this.onDeliveredFilterValue == true && this.notDeliveredFilterValue == true) {
                selectedScheduleDetailFilter.onDelivered = null;
            }

            // three combination of filter-panel
            // 1.Ontime true
            else if (this.onTimeFilterValue == true && this.lateFilterValue == true && this.onDeliveredFilterValue == true && this.notDeliveredFilterValue == false) {
                selectedScheduleDetailFilter.onDelivered = true;
            } 
            else if (this.onTimeFilterValue == true && this.lateFilterValue == true && this.onDeliveredFilterValue == false && this.notDeliveredFilterValue == true) {
                selectedScheduleDetailFilter.onDelivered = null;
            }
            else if (this.onTimeFilterValue == true && this.lateFilterValue == false && this.onDeliveredFilterValue == true && this.notDeliveredFilterValue == true) {
                selectedScheduleDetailFilter.onTime = true;
                selectedScheduleDetailFilter.onDelivered = null;
            }
            // 1.late true
            else if (this.onTimeFilterValue == false && this.lateFilterValue == true && this.onDeliveredFilterValue == true && this.notDeliveredFilterValue == true) {
                selectedScheduleDetailFilter.onTime = false;
                selectedScheduleDetailFilter.onDelivered = null;
            } 

            // four combination of filter-panel
            else if (this.onTimeFilterValue == true && this.lateFilterValue == true && this.onDeliveredFilterValue == true && this.notDeliveredFilterValue == true) {
                selectedScheduleDetailFilter.onDelivered = null;
            }
            else if (this.onTimeFilterValue == false && this.lateFilterValue == false && this.onDeliveredFilterValue == false && this.notDeliveredFilterValue == false) {
                selectedScheduleDetailFilter.onTime = null;
                selectedScheduleDetailFilter.onDelivered = null;
            }
            selectedScheduleDetailFilter.ScheduledFeedId =  this.ScheduledFeedId;
            selectedScheduleDetailFilter.date = this.selectedDate;

            this.commonExpandFunction(selectedScheduleDetailFilter);
        },

        // Apply date filter 
        date(date) {
            this.selectedDate = date.toLocaleDateString();
            this.commonMultiSechduleFilterChange();
        },

        // clear all event
        clearAllFilters() {
            this.$nextTick(() => {
                this.getDateFilter();
                
            this.onTimeFilterValue = false;
            this.lateFilterValue = false;
            this.onDeliveredFilterValue = false;
            this.notDeliveredFilterValue = false;
            this.getOnTimeList([]);
            this.getDeliveredList([]);
            })
            var data = {
                ScheduledFeedId: this.ScheduledFeedId,
                onTime: null,
                onDelivered: null,
                date: this.initialSelectedDate,
            };
            this.selectedDate = this.initialSelectedDate;
            this.commonExpandFunction(data);
        },

        getOnTimeList(selectedFilter) {
            this.filterObject[1].filterList = [
                        {
                            id: 1,
                            lable: "On Time",
                            isChecked: false,
                        },
                        {
                            id: 2,
                            lable: "Late",
                            isChecked: false,
                        },
                    
                    ],

            this.filterObject[1].filterList.map(x => {
                    selectedFilter.forEach(y => {
                        if (x.lable === y.value.lable) {
                            x.isChecked = true;
                        }
                    })
                });
         },

         getDeliveredList(selectedFilter) {
            this.filterObject[2].filterList = [
                        {
                            id: 1,
                            lable: "Delivered",
                            isChecked: false,
                        },
                        {
                            id: 2,
                            lable: "Not Delivered",
                            isChecked: false,
                        },
                    ],

            this.filterObject[2].filterList.map(x => {
                    selectedFilter.forEach(y => {
                        if (x.lable === y.value.lable) {
                            x.isChecked = true;
                        }
                    })
                });
         },
    },
};
</script>
