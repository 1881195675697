<template>
    <div id="scheduleDetail">
        <loader-component v-if="scheduleListDetail"></loader-component>
        <mds-layout-grid>
            <mds-row style="margin: 0">
                <div
                    :class="[
                        { showFilterLeftBlock: showFilterPanel },
                        'leftBlock',
                    ]"
                    :style="cssVariables"
                >
                    <div class="scheduleHeaderSection">
                        <div class="schedule-navigation">
                            <span>
                                <router-link
                                    :to="{
                                        name: getRouteDetails(
                                            '/data-dissemination/schedules'
                                        ),
                                    }"
                                >
                                    Schedules
                                </router-link>
                            </span>
                            <span class="create_schedule_router">
                                / Schedules Details
                            </span>
                        </div>
                        <div>
                            <h2 class="schedule-header">Schedules Details</h2>
                        </div>
                    </div>
                    <div class="scheduleTable">
                        <div class="schedule_table">
                            <mds-table>
                                <mds-thead>
                                    <mds-th
                                        v-for="(
                                            header, index
                                        ) in scheduleHeader"
                                        :key="index"
                                    >
                                        {{ header.text }}
                                    </mds-th>
                                </mds-thead>
                                <mds-tbody>
                                    <mds-tr
                                        v-for="(
                                            row, index
                                        ) in scheduleSingleRow"
                                        :key="index"
                                    >
                                        <mds-td
                                            v-for="(
                                                header, i
                                            ) in scheduleHeader"
                                            :key="i"
                                            :right-aligned="
                                                header.align === 'right'
                                            "
                                        >
                                            <template
                                                v-if="
                                                    header.fieldName ===
                                                    'timepercent'
                                                "
                                            >
                                                <span
                                                    class="schedule-colourcode"
                                                    :style="{
                                                        'background-color':
                                                            colorCodeScheduleRow(
                                                                row[
                                                                    header
                                                                        .fieldName
                                                                ]
                                                            ),
                                                    }"
                                                ></span>
                                            </template>
                                            <template
                                                v-if="
                                                    header.fieldName ===
                                                    'deliverypercent'
                                                "
                                            >
                                                <span
                                                    class="schedule-colourcode"
                                                    :style="{
                                                        'background-color':
                                                            colorCodeScheduleRow(
                                                                row[
                                                                    header
                                                                        .fieldName
                                                                ]
                                                            ),
                                                    }"
                                                ></span>
                                            </template>
                                            <template>
                                                {{ row[header.fieldName] }}
                                            </template>
                                        </mds-td>
                                    </mds-tr>
                                </mds-tbody>
                            </mds-table>

                            <!-- detail schedule table -->
                            <mds-table
                                class="detail-schedule-table"
                                row-hover
                                show-sortable
                                v-if="scheduledetailLength > 0"
                            >
                                <mds-thead
                                    :indeterminate="
                                        selectionScheduledetailStatus.isIndeterminate
                                    "
                                >
                                    <mds-th
                                        v-for="(
                                            header, index
                                        ) in scheduleDetailHeader"
                                        :key="index"
                                        :width="header.width"
                                        :sortable="header.sortable"
                                        :sorted="header.sorted"
                                        :right-aligned="
                                            header.align === 'right'
                                        "
                                        @mds-th-sort-changed="
                                            handleSortEvent(
                                                index,
                                                header.fieldName,
                                                $event
                                            )
                                        "
                                    >
                                        {{ header.text }}
                                    </mds-th>
                                </mds-thead>
                                <mds-tbody>
                                    <mds-tr
                                        v-for="(
                                            row, index
                                        ) in scheduleDetailRow"
                                        :key="index"
                                    >
                                        <mds-td
                                            v-for="(
                                                header, i
                                            ) in scheduleDetailHeader"
                                            :key="i"
                                            :right-aligned="
                                                header.align === 'right'
                                            "
                                        >
                                            <template
                                                v-if="
                                                    header.fieldName ===
                                                    'ontime'
                                                "
                                            >
                                                <span
                                                    class="schedule-colourcode"
                                                    :style="{
                                                        'background-color':
                                                            colorCodeOntime(
                                                                row[
                                                                    header
                                                                        .fieldName
                                                                ]
                                                            ),
                                                    }"
                                                ></span>
                                            </template>
                                            <template
                                                v-if="
                                                    header.fieldName ===
                                                    'isdelivered'
                                                "
                                            >
                                                <span
                                                    class="schedule-colourcode"
                                                    :style="{
                                                        'background-color':
                                                            colorCodeOntime(
                                                                row[
                                                                    header
                                                                        .fieldName
                                                                ]
                                                            ),
                                                    }"
                                                ></span>
                                            </template>

                                            <template
                                                v-if="
                                                    header.fieldName ===
                                                    'actions'
                                                "
                                            >
                                                <span
                                                    ><mds-button
                                                        class="actionButton"
                                                        @click="
                                                            rowDownloadActionButton(
                                                                row
                                                            )
                                                        "
                                                        icon="download"
                                                        variation="icon-only"
                                                        type="button"
                                                    ></mds-button
                                                ></span>
                                            </template>
                                            <template
                                                v-if="
                                                    header.fieldName ===
                                                        'deliverytime' &&
                                                    row[header.fieldName] !=
                                                        null
                                                "
                                            >
                                                {{
                                                    convertDate(
                                                        row[header.fieldName]
                                                    )
                                                }}
                                            </template>
                                            <template v-else>
                                                {{ row[header.fieldName] }}
                                            </template>
                                        </mds-td>
                                    </mds-tr>
                                </mds-tbody>
                            </mds-table>
                        </div>
                        <mds-empty-state
                            v-if="scheduleDetailRow == undefined"
                            class="no-results-message"
                            size="medium"
                            title="No results matched"
                            message="We weren't able to match any results with your current selections. Please adjust your filter criteria and try again."
                        ></mds-empty-state>
                    </div>
                </div>

                <div
                    :class="[
                        { showFilterRightBlock: showFilterPanel },
                        'rightBlock',
                    ]"
                    :style="cssVariables"
                >
                    <div class="filterPanel">
                        <transition-group name="fade" class="group-4">
                            <filter-panel
                                :key="checkBoxFilterKey"
                                :filterData="filterObject"
                                @filterEvent="searchFilter"
                                @multiSelectFilterChange="
                                    multiSelectFilterChange
                                "
                                @hideFilterPanel="closeFilterPanel"
                                :isFilterPanelOpen="showFilterPanel"
                                @showFilterPanel="openFilterPanel"
                                :showSearchInput="false"
                                @clearInputFilter="clearTextFilter"
                                @clearAllFilters="clearAllFilters"
                                @date="date"
                            ></filter-panel>
                        </transition-group>
                    </div>
                </div>
            </mds-row>
        </mds-layout-grid>
    </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow } from "@mds/layout-grid";
import {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTr,
    MdsTbody,
    MdsTd,
} from "@mds/data-table-4.0.2";
import FilterPanel from "../../../common_components/FilterPanel.vue";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import MdsEmptyState from "@mds/empty-state";
import { MdsButton } from "@mds/button";
import { dateFormatterMixin } from "../../../../mixins/date.js";

export default {
    name: "CreateScheduleDetail",
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsTable,
        MdsThead,
        MdsTh,
        MdsTr,
        MdsTbody,
        MdsTd,
        FilterPanel,
        LoaderComponent,
        MdsEmptyState,
        MdsButton,
    },
    mixins: [dateFormatterMixin],

    data() {
        return {
            showLoader: false,
            showFilterPanel: false,
            isFilterPanelOpen: true,
            checkBoxFilterKey: 0,
        };
    },
    props: {
        scheduleListDetail: {
            type: Boolean,
            default: false,
        },
        filterObject: {
            type: Array,
            default: () => [],
        },
        menuwidth: {
            type: String,
            default: "",
        },
        scheduleHeader: {
            type: Array,
            default: () => [],
        },
        scheduleSingleRow: {
            type: Array,
            default: () => [],
        },
        scheduleDetailHeader: {
            type: Array,
            default: () => [],
        },
        scheduleDetailRow: {
            type: Array,
        },
    },
    async mounted() {},
    computed: {
        scheduledetailLength() {
            if (this.scheduleDetailRow !== undefined) {
                if (this.scheduleDetailRow.length > 0) {
                    return this.scheduleDetailRow.length;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        },

        selectionScheduledetailStatus() {
            const filtered = this.scheduleDetailRow.filter(
                (row) => row.checked === true
            );

            return {
                isIndeterminate:
                    filtered.length > 0 &&
                    filtered.length < this.scheduleDetailRow.length,
                isAllSelected:
                    filtered.length === this.scheduleDetailRow.length &&
                    filtered.length != 0 &&
                    this.scheduleDetailRow != 0,
            };
        },

        cssVariables() {
            return {
                "--menuWidth": this.menuwidth,
            };
        },
    },
    methods: {
        getRouteDetails(url) {
            const routeDetails = this.$router.resolve({
                path: url,
            });
            return routeDetails.resolved.name;
        },
        rowDownloadActionButton(value) {
            this.$emit("rowDownloadActionButton", value);
        },
        //Start Filter Panel
        openFilterPanel(selectedFilter) {
            this.showFilterPanel = true;
            this.$emit("openFilterPanel", selectedFilter);
        },

        closeFilterPanel() {
            this.showFilterPanel = false;
        },

        clearTextFilter() {
            this.$emit("clearTextFilter");
        },

        clearAllFilters() {
            this.$emit("clearAllFilters");
        },

        searchFilter(searchText) {
            this.$emit("searchFilter", searchText);
        },

        date(date, type) {
            this.$emit("date", date, type);
        },

        multiSelectFilterChange(event, value, headerTypeDetails) {
            this.$emit(
                "multiSelectFilterChange",
                event,
                value,
                headerTypeDetails
            );
        },
        //End Filter Panel

        colorCodeOntime(status) {
            if (status == "On Time") {
                return "#00AF41";
            } else if (status == "Late") {
                return "#FF0000";
            } else if (status == "Delivered") {
                return "#00AF41";
            } else if (status == "Not Delivered") {
                return "#FF0000";
            }
        },

        colorCodeScheduleRow(status) {
            if (status.split("%")[0] < 100) {
                return "#FF0000";
            } else if (status.split("%")[0] == 100) {
                return "#00AF41";
            } else if (status === "\u2014") {
                return "";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

.schedule-header {
    margin-top: 16px;
    @include mds-level-2-heading($bold: false);
}

.schedule-navigation {
    margin-top: 16px;
}

.scheduleTable {
    margin-top: 13px;

    .schedule_table {
        margin: 0px;
        height: auto;
        max-height: 70vh;
        overflow: auto;
    }
}

.tableDataRow {
    padding: 0;
}

.leftBlock {
    max-width: 94%;
    position: relative;
    overflow: hidden;
    margin-left: 16px;
    margin-right: 71px;
}

.rightBlock {
    width: 55px;
    padding: 0;
    margin-left: calc(100vw - 55px - var(--menuWidth));
    min-height: 100vh;
    border: 1px solid #979797;
    border-top: 1px solid #cccccc;
    position: fixed;
    z-index: 1;
    background-color: white;
}

.showFilterRightBlock {
    width: 292px;
    margin-left: calc(100vw - 292px - var(--menuWidth));
    position: fixed;
    z-index: 1;
}

.showFilterLeftBlock {
    max-width: 96%;
    margin-left: 16px;
    margin-right: 71px;
}

.mds-data-table__header-selection___VueMDS3Demo {
    left: -1px;
    position: relative;
    vertical-align: bottom;
    width: 15px;
}

::v-deep .mds-data-table__header-selection___VueMDS3Demo {
    left: -1px;
    position: relative;
    text-align: left;
    vertical-align: bottom;
    width: 2%;
}

::v-deep .mds-data-table__table___VueMDS3Demo {
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
    color: #1e1e1e;
    display: table;
    max-width: 100%;
    table-layout: fixed;
    width: 100%;
    margin-top: 16px;
}

.actionButton {
    margin-left: 5px;
    margin-right: 5px;
}

.no-results-message {
    margin: auto;
    min-width: 50%;
    margin-top: 16px;
}

.move-pagination-show-info::v-deep .mds-pagination__show-items___VueMDS3Demo {
    margin-right: calc(292px - 55px);
}

.schedule-colourcode {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 3%;
}

.detail-schedule-table {
    margin-top: $mds-space-4-x;
}
</style>